import Accordion from "../components/accordion";
import Button from "../components/button";
import CounterCard from "../components/counter-card";
import IconList from "../components/icon-list";
import data from "../data/career-page-data.json";
import ContactForm from "../components/form-template";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import SubTitle from "../components/SubTitle";
import SEO from "../components/SEO";
import seoData from "../data/seo-data.json";
import { Link } from "react-router-dom";
const CareerPage = () => {
  const { iconListData, jobs } = data;
  const careerData = seoData.careers;
  AOS.init({
    duration: 1000,
    offset: 200,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.refresh();
  }, []);
  const location = useLocation();
  const jobRef = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get("to");
    const jobElement = document.getElementById(jobId);

    if (jobId === "job" && jobElement) {
      const rect = jobElement.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const elementPosition = rect.top + scrollTop;
      // Determine the offset based on the window's width
      const offset =
        window.innerWidth <= 768
          ? window.innerHeight * 0.1
          : window.innerWidth * 0.1;

      // Scroll to the position offset pixels before the element
      window.scrollTo({ top: elementPosition - offset, behavior: "smooth" });
    } else {
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.search]);

  return (
    <>
      <SEO
        title={careerData.title}
        description={careerData.description}
        ogTitle={careerData.ogTitle}
        ogImage={careerData.ogImage}
        keywords={seoData.keywords.join(", ")}
      />
      <section className="bg-radial-gradient backdrop-blur pt-32 pb-20 md:pt-52 2xl:pb-48 2xl:pt-60 md:pb-28 md:relative overflow-y-hidden">
        <img
          className="w-[800px] bg-animation hidden lg:block absolute -top-96 right-0 circle-shape-career"
          src="assets/shape/full-circle.svg"
          alt="img"
        />
        <img
          className=" hidden bg-animation w-[800px] left-0 top-96 lg:block absolute circle-shape-career"
          src="assets/shape/half-circle.svg"
          alt="img"
        />
        <div className="container md:relative" data-aos="fade-up">
          <div className="grid grid-cols-6 gap-4">
            <div className="lg:col-span-4 col-span-5 ">
              <h1 className="text-primary text-7xl md:text-8xl md:leading-[100px] font-semibold relative">
                Ready to make an{" "}
                <SubTitle
                  title={"impact?"}
                  className={"inline-block pb-2 text-alabaster"}
                  innerClass={"bottom-0 left-0"}
                  animationType={"fade-in"}
                />
              </h1>
              <p className="md:text-3xl text-xl mt-24 text-white/80 leading-normal 2xl:max-w-[80%]">
                Embark on a transformative journey with us. Your potential meets
                purpose, and together, we shape a meaningful tomorrow.
              </p>
              <div className="md:text-left text-center">
                <Link to="?to=job">
                  <Button
                    type="arrow-btn"
                    arrow={true}
                    customClass="mt-16 bg-whiteSmoke !transition-all rounded-51 text-secondary px-6 py-2 inline-flex items-center group"
                    innerCustomClass={
                      "text-lg group-hover:scale-110 font-medium mr-2 !transition-all ease-out duration-300"
                    }
                  >
                    Apply
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="bg-secondary lg:pt-40 md:pt-16 pt-10 md:pb-20 pb-10">
        <div className="container" data-aos="fade-up">
          <p className="text-white md:text-4xl text-xl md:leading-[63px] font-medium text-center max-w-4xl mx-auto">
            Welcome to a workplace where innovation thrives, and culture defines
            our success. At TygrLabs, our strength lies not just in what we do
            but in how we do it together. We've embraced a culture that values
            your unique contributions, encourages collaboration, and celebrates
            every milestone. Your journey here promises growth, camaraderie, and
            the excitement of shaping tomorrow. Let's make it extraordinary!
          </p>
          <div className="text-center mt-12">
            <img
              src="assets/images/country_manager.png"
              alt="img"
              data-aos="fade-up"
              className="rounded-[23px] w-20 md:w-[190px] object-cover mx-auto"
            />
            <h4 data-aos="fade-up" className="text-primary mt-10">
              Fahmid Hossain
            </h4>
            <p data-aos="fade-up" className="text-white text-2xl font-normal">
              Country Manager
            </p>
          </div>
        </div>
      </section> */}
      <section className="bg-secondary md:py-20 py-10">
        <div className="container" data-aos="fade-up">
          <h3 className="text-white text-4xl font-medium text-center">
            Our People, Our Power
          </h3>
          <div className="flex flex-col lg:flex-row gap-8 justify-center items-center mt-14 md:mt-28">
            <CounterCard
              number="93"
              suffix="%"
              description="Employee Retention Rate"
            />
            <CounterCard
              number="100"
              suffix="%"
              description="Employee Growth"
              cardColor="white"
            />
          </div>
          <div className="text-center mt-8">
            <CounterCard
              number="85"
              suffix="%"
              description="Employee Satisfaction"
              cardColor="primary"
            />
          </div>
        </div>
      </section>
      <section className="bg-secondary md:pt-28 md:relative py-8 md:pb-20">
        <img
          className="absolute lg:block hidden bg-animation right-0 top-96"
          src="assets/shape/semi-circle.svg"
          alt="img"
        />
        <div className="container">
          <h2 data-aos="fade-up">
            Your Pathway to&nbsp;
            <span className="text-primary">Self-Actualization</span>
          </h2>
          <div
            className="grid grid-cols-2 lg:gap-24 md:gap-8 gap-6 mt-20 md:mt-28 md:px-28"
            data-aos="fade-up"
          >
            {iconListData &&
              iconListData.length > 0 &&
              iconListData.map((value, key) => {
                return (
                  <div
                    className="md:col-span-1 col-span-full md:mt-20 mt-10 max-sm:first-of-type:mt-0"
                    key={key}
                  >
                    <IconList
                      heading={value.heading}
                      description={value.description}
                      icon={value.icon}
                      type="vertical"
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section className="bg-secondary pt-20 pb-32 md:relative">
        <img
          className="absolute lg:block hidden right-0 bg-animation top-36"
          src="assets/shape/semi-circle.svg"
          alt="img"
        />
        <img
          className="absolute lg:block hidden left-0 bg-animation top-1/2"
          src="assets/shape/180.svg"
          alt="img"
        />
        <div className="container md:relative z-[1]">
          <h2 data-aos="fade-up">
            <span className="text-primary">Perks</span> of Working with us!
          </h2>
          <div className="grid grid-cols-12 grid-rows-[repeat(7,auto)] gap-6 mt-28">
            <div
              className="md:col-span-7 col-span-full  rounded-30"
              data-aos="fade-up"
            >
              <img
                src="/assets/images/img1.svg"
                alt="img"
                className="w-full h-full object-cover rounded-[inherit]"
              />
            </div>
            <div
              className="rounded-30 md:col-span-5 col-span-full bg-primary/40 lg:py-8 p-4 lg:pl-10 lg:pr-10 flex flex-col justify-between"
              data-aos="fade-up"
            >
              <h4 className="text-white lg:max-w-72">
                Where Fun Meets Fierce Competition
              </h4>
              <p className="font-normal text-white lg:text-xl text-sm md:leading-[26px] mt-14 lg:mt-20 2xl:mt-32">
                Unleash your competitive spirit in regular gaming tournaments,
                fostering a fun and engaging atmosphere.
              </p>
            </div>

            <div
              className="rounded-30 bg-white md:col-span-5 col-span-full lg:py-8 p-4 lg:pl-10 lg:pr-14 flex flex-col justify-between"
              data-aos="fade-up"
            >
              <h4 className="text-secondary lg:max-w-56">
                Explore, Relax, Repeat
              </h4>
              <p className="font-normal text-secondary text-sm lg:text-xl md:leading-[26px] mt-14 lg:mt-20 2xl:mt-32">
                Bond with colleagues during annual getaways, creating lasting
                memories in a relaxed and enjoyable setting.
              </p>
            </div>
            <div
              className="rounded-30 md:col-span-7 col-span-full"
              data-aos="fade-up"
            >
              <img
                src="/assets/images/img2.svg"
                alt="img"
                className="w-full h-full object-cover rounded-[inherit]"
              />
            </div>

            <div
              className="rounded-30  md:col-span-4 col-span-full row-span-2"
              data-aos="fade-up"
            >
              <img
                src="/assets/images/img3.svg"
                alt="img"
                className="w-full h-full object-cover rounded-[inherit]"
              />
            </div>
            <div
              className="rounded-30 bg-primary md:col-span-8 col-span-full lg:py-8 lg:pl-10 lg:pr-20 p-4 row-span-1 flex flex-col justify-betweenflex justify-between"
              data-aos="fade-up"
            >
              <h4 className="text-white lg:max-w-64">
                Kick Off a Healthy Lifestyle
              </h4>
              <p className="font-normal text-white text-sm lg:text-xl md:leading-[26px] mt-14 lg:mt-20 2xl:mt-32">
                Stay active and build camaraderie through regular football
                matches and other sports activities.
              </p>
            </div>

            <div
              className="rounded-30  md:col-span-4 col-span-full row-span-1"
              data-aos="fade-up"
            >
              <img
                src="/assets/images/img4.svg"
                alt="img"
                className="w-full h-full object-cover rounded-[inherit]"
              />
            </div>
            <div
              className="rounded-30  md:col-span-4 col-span-full row-span-2"
              data-aos="fade-up"
            >
              <img
                src="/assets/images/img5.svg"
                alt="img"
                className="w-full h-full object-cover rounded-[inherit]"
              />
            </div>

            <div
              className="rounded-30  bg-white/10 md:col-span-8 col-span-full lg:py-8 lg:pl-10 lg:pr-20 p-4 row-span-1 flex flex-col justify-between"
              data-aos="fade-up"
            >
              <h4 className="text-white lg:max-w-64">
                Beyond the Office Walls
              </h4>
              <p className="font-normal text-white text-sm lg:text-xl md:leading-[26px] mt-14 lg:mt-20 2xl:mt-32">
                Enjoy team-building outings each month, exploring local
                attractions, trying new restaurants, or participating in
                exciting activities together.
              </p>
            </div>

            <div className="rounded-30 col-span-12" data-aos="fade-up">
              <img
                src="/assets/images/img6.svg"
                alt="img"
                className="w-full h-full object-cover rounded-[inherit]"
              />
            </div>

            <div
              className="rounded-30 md:col-span-4 col-span-full row-span-1"
              data-aos="fade-up"
            >
              <img
                src="/assets/images/img7.svg"
                alt="img"
                className="w-full h-full object-cover rounded-[inherit]"
              />
            </div>

            <div
              className="rounded-30 bg-white md:col-span-8 col-span-full lg:py-8 p-4 lg:pl-10 lg:pr-14 row-span-1 flex flex-col justify-between"
              data-aos="fade-up"
            >
              <h4 className="text-secondary lg:max-w-[26rem]">
                Celebrating Your Outstanding Contributions!
              </h4>
              <p className="font-normal text-secondary text-sm lg:text-xl md:leading-[26px] mt-14 lg:file:mt-[5.9rem] 2xl:mt-[5.9rem]">
                Celebrate achievements through employee recognition programs,
                acknowledging outstanding contributions.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="job" ref={jobRef} className="bg-primary py-20">
        <div className="container">
          <h2 className="text-start mb-14" data-aos="fade-up">
            Job Openings
          </h2>

          {jobs &&
            jobs.length > 0 &&
            jobs.map((value, index) => {
              return (
                <Accordion
                  heading={value.jobCategory}
                  key={index}
                  index={index}
                >
                  <div className="grid grid-cols-4" data-aos="fade-up">
                    <div className="lg:col-span-3 lg:col-start-2 col-start-1 col-span-3">
                      <ul className="text-white md:text-2xl text-lg ">
                        {value.postions &&
                          value.postions.length > 0 &&
                          value.postions.map((position, index) => {
                            return (
                              <li key={index} className="mb-3.5">
                                <a href={position.link} className="">
                                  {position.title}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </Accordion>
              );
            })}
        </div>
      </section>
      <section className="bg-lightPrimary py-28">
        <div className="container">
          <h2
            className="text-left lg:max-w-4/5 md:text-5xl md:leading-normal lg:text-6xl lg:leading-[90px]"
            data-aos="fade-up"
          >
            Didn't Find the Perfect Fit? Let's Create It Together! 
          </h2>
          <h3
            className="mt-20 font-medium md:text-3xl text-xl text-white mb-10"
            data-aos="fade-up"
          >
            Tell us what you bring to the table
          </h3>
          <ContactForm
            cv={true}
            cvReq={true}
            btnColor="!bg-secondary text-white"
            bgColor="bg-whiteSmoke border-solid border-2 border-sky-500 outline-0"
            placeholderEmail="Email (Required)"
            placeholderText="Tell us what you do (Required)"
          />
        </div>
      </section>
    </>
  );
};

export default CareerPage;
