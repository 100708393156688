import React, { useState } from "react";
import Button from "./button";
import axios from "axios";
import { Modal } from "./modal";
import Loader from "./loader";
import { ToastContainer, toast } from "react-toastify";
import apiConfig from "../apiConfig";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = (props) => {
  const { btnColor, cv, bgColor, placeholderEmail, placeholderText, cvReq } =
    props;
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPDFValid, setIsPDFValid] = useState(true);

  const [formData, setFormData] = useState({
    email: "",
    message: "",
    cv: "",
    isApply: false,
  });
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file?.name || "";
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    // Check if the file is a PDF
    if (file && fileExtension !== ".pdf") {
      toast.error("Please upload a valid PDF file.");
      setIsPDFValid(false);
      setFile("");
      setFormData({ ...formData, cv: "" });
    } else {
      setIsPDFValid(true);
      setFile(
        fileName.length > 10
          ? `${fileName.slice(0, 10)}..${fileExtension}`
          : fileName
      );
      const reader = new FileReader();
      if(file){
        // Convert to MB
        const sizeInMB = file.size / (1024 * 1024);
        if((sizeInMB > 20)) {
          toast.error("File size cannot be more than 20MB.");
          setFile("");
          setIsPDFValid(false);
          setFormData({ ...formData, cv: "" });
          return;
        }else{
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            setFormData({ ...formData, cv: reader.result });
          };
        }
       
      }
    }
  };

  const validate = () => {
    let isValid = true;
    if (!formData.email) {
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
    }
    if (!formData.message) {
      isValid = false;
    }
    if (cvReq) {
      if (!formData.cv) {
        isValid = false;
      }
    }
    // Ensure the file is valid if CV is required
    if (cvReq && !isPDFValid) {
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      axios
        .post(`${apiConfig.BASE_URL_PROD}/send-email`, formData)
        .then((res) => {
          setLoading(false);
          document.body.style.overflow = "hidden";
          if (res.data.success) {
            setOpen(true);
            clearForm();
            document.body.style.overflow = "hidden";
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Failed to send message. Please try again.");
        });
    }
  };

  const clearForm = ()=>{
      document.getElementById("email").value = "";
      document.getElementById("exampleFormControlTextarea13").value = "";
      setFile("");
  }

  return (
    <div className="block">
      {loading && <Loader />}
      <input
        type="email"
        name="email"
        id="email"
        placeholder={placeholderEmail}
        className={`${bgColor} mb-6 text-secondary placeholder-secondary rounded-42 w-full py-3 px-6`}
        onChange={handleChange}
        data-aos="fade-up"
      />
      <textarea
        id="exampleFormControlTextarea13"
        placeholder={placeholderText}
        rows={6}
        name="message"
        className={`${bgColor} mb-6 text-secondary/50 rounded-42 w-full p-6`}
        onChange={handleChange}
        data-aos="fade-up"
      />

      {cv && (
        <div
          className="flex justify-between bg-whiteSmoke rounded-42 w-full py-2 px-2"
          data-aos="fade-up"
        >
          <div className="my-auto">
            <h3 className="ml-4 text-secondary">Upload CV {"(Required)"}</h3>
          </div>
          <div className="flex items-center">
            <label
              htmlFor="cvUpload1"
              className="bg-[#271E1C] text-white rounded-full px-6 py-1 mr-1 cursor-pointer"
            >
              {file ? file : "Upload (PDF ONLY)"}
            </label>
            <input
              name="cv"
              type="file"
              id="cvUpload1"
              className="hidden"
              onChange={handleFileChange}
            />
          </div>
        </div>
      )}
      <div className="text-right">
        <Button
          action={handleSubmit}
          type="arrow-btn"
          customClass={`mt-10 ${btnColor} ${
            !validate()
              ? "cursor-not-allowed opacity-50 pointer-events-none"
              : "cursor-pointer opacity-1 pointer-events-auto"
          }`}
          disabled={!validate()}
        >
          Enter
        </Button>
      </div>
      <Modal
        openModal={open}
        setOpenModal={(isOpen) => {
          setOpen(isOpen);
          if (!isOpen) {
            document.body.style.overflow = "auto"; // Enable scrolling when modal closes
          }
        }}
        title={"Thanks for messaging us!"}
        description={"We will get back to you soon."}
      />

      <ToastContainer
        position="bottom-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ContactForm;
