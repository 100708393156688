import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {
  AOS.init({
    duration: 1000,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="bg-radial-gradient pt-28 pb-20 md:relative lg:pt-40 md:pt-32 md:pb-28 2xl:pt-56 2xl:pb-40 z-10 ">
        <img
          className="w-[800px] bg-animation hidden md:block absolute -top-96 right-0 circle-shape-career -z-10"
          src="assets/shape/full-circle.svg"
          alt="img"
        />
        <img
          className="hidden w-[800px] bg-animation top-[450px] left-20 md:block absolute circle-shape-career  -z-10"
          src="assets/shape/half-circle1.svg"
          alt="img"
        />
        <div className="container">
          <div className="grid grid-cols-12">
            <h1 className="col-span-full text-primary text-7xl lg:text-8xl lg:leading-tight font-semibold text-left leading-none">
              What we do
            </h1>
            <div
              className="lg:mt-44 md:mt-20 mt-6 bg-primary rounded w-full col-span-full h-1 md:mb-14 mb-24"
              data-aos="fade-left"
            ></div>
            <h2
              className="sm:col-span-5 col-span-full mb-8 text-alabaster font-medium align-top text-left leading-none text-[40px]"
              data-aos="fade-up"
            >
              Our Services
            </h2>
            <div className="sm:col-span-1 sm:block hidden"></div>
            <p
              className="sm:col-span-6 col-span-full text-alabaster text-2xl sm:pl-2 lg:leading-9 md:leading-6"
              data-aos="fade-up"
            >
              Our services include digital marketing, social media management,
              content creation, SEO, and analytics. We take a holistic approach
              to maximize your brand’s visibility, engagement, and growth across
              all platforms.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-secondary relative overflow-visible">
        <div className="container">
          <img
            className="absolute hidden bg-animation lg:block left-0 top-10"
            src="assets/shape/180.svg"
            alt="img"
          />
          <img
            className="absolute bg-animation hidden lg:block right-0 bottom-10"
            src="assets/shape/semi-circle.svg"
            alt="img"
          />
          <div className="grid grid-cols-12">
            {/* Start: Product Design */}
            <div
              className="col-span-full  bg-productDesign md:rounded-[25px] rounded-51 md:-mt-14 -mt-10 relative z-20 backdrop-blur-md lg:pl-16 md:pl-10 lg:min-h-[425px] md:min-h-[350px] flex md:flex-row flex-col-reverse items-center overflow-hidden lg:mb-12 md:mb-5 md:max-w-none max-w-sm mx-auto"
              data-aos="fade-up"
            >
              <div className="md:w-3/6 md:pr-4 w-full px-9 sm:py-12 pb-12 md:mt-0 sm:-mt-20 -mt-10 ">
                <h3 className="text-alabaster lg:text-4xl md:text-[28px] sm:text-2xl text-lg leading-none font-semibold inline-block align-middle">
                  <img
                    src="assets/images/product-design-icon.svg"
                    alt="Product Design"
                    className="inline lg:w-auto sm:w-6 w-5 object-contain object-left-top"
                  />
                  <span className="ml-3">Product Design</span>
                </h3>
                <p className="text-alabaster text-xs sm:text-sm md:text-lg lg:text-xl leading-1 lg:pt-9 pt-4">
                  Great design is the foundation of exceptional products. Our
                  design team focuses on creating user-centric, visually
                  compelling experiences that align with your brand identity.
                  From initial concept to final design, we ensure that every
                  detail enhances user engagement and satisfaction.
                </p>
              </div>
              <div className="md:w-3/6 w-full md:h-full">
                <img
                  src="assets/images/product-design-masked.png"
                  alt="product-design"
                  className="w-full h-full md:block hidden object-cover"
                />
                <img
                  src="assets/images/product-design-image.png"
                  alt="product-design"
                  className="w-full h-full md:hidden block object-cover"
                />
              </div>
            </div>
            {/* End: Product Design */}

            {/* Start: Product Development */}
            <div
              className="col-span-full relative md:before:content-[''] before:absolute lg:before:w-[80%] md:before:w-[84%] before:w-full before:h-[calc(100%-1px)] md:before:top-0 before:top-24 before:right-0 before:-z-10 z-1 before:bg-alabaster md:rounded-[25px] rounded-51 md:mt-0 sm:mt-12 mt-5 z-20 backdrop-blur-md lg:pr-16 md:pr-10 lg:min-h-[425px] md:min-h-[350px] flex md:flex-row flex-col items-center overflow-hidden lg:mb-12 md:mb-5 md:max-w-none max-w-sm mx-auto"
              data-aos="fade-up"
            >
              <div className="md:w-3/6 w-full md:h-full p-0 m-0">
                <img
                  src="assets/images/product-development-masked.png"
                  alt="product-development"
                  className="w-full h-full md:block hidden object-cover border-none p-0 scale-100"
                />
                <img
                  src="assets/images/product-development-image.png"
                  alt="product-development"
                  className="w-full h-full md:hidden block object-cover"
                />
              </div>
              <div className="md:w-3/6 md:pr-4 w-full px-9 sm:py-12 pb-12 md:mt-0 sm:-mt-20 -mt-10">
                <h3 className="text-secondary lg:text-4xl md:text-[28px] sm:text-2xl text-lg leading-none font-semibold inline-block align-middle">
                  <img
                    src="assets/images/product-development-icon.svg"
                    alt="Product development"
                    className="inline lg:w-auto sm:w-6 w-5 object-contain object-left-top"
                  />
                  <span className="ml-3">Product Development</span>
                </h3>
                <p className="text-secondary text-xs sm:text-sm md:text-lg lg:text-xl leading-1 lg:pt-9 pt-4">
                  We specialize in turning innovative ideas into fully
                  functional products. Our development team works on full-stack
                  solutions, ensuring seamless integration, scalability, and
                  technical excellence. We are dedicated to delivering
                  high-quality products that meet market demands and exceed
                  expectations.
                </p>
              </div>
            </div>
            {/* End: Product Development */}

            {/* Start: Brand Strategy */}
            <div
              className="col-span-full relative before:content-[''] before:absolute md:before:w-[80%] before:w-full before:h-[calc(100%-1px)] md:before:top-0 before:top-36 before:left-0 before:-z-10 z-1 before:bg-primary md:rounded-[25px] rounded-51 md:mt-0 sm:mt-12 mt-5 z-20 backdrop-blur-md  lg:min-h-[425px] md:min-h-[350px] flex md:flex-row flex-col-reverse items-center overflow-hidden lg:mb-12 md:mb-5 md:max-w-none max-w-sm mx-auto"
              data-aos="fade-up"
            >
              <div className="md:w-3/6 md:pr-4 w-full px-9 sm:py-12 pb-12 md:mt-0 sm:-mt-20 -mt-10">
                <h3 className="text-alabaster lg:text-4xl md:text-[28px] leading-none font-semibold inline-block align-middle">
                  <img
                    src="assets/images/brand-strategy-icon.svg"
                    alt="Brand Strategy"
                    className="inline lg:w-auto md:w-6 object-contain object-left-top"
                  />
                  <span className="ml-3">Brand Strategy</span>
                </h3>
                <p className="text-alabaster md:text-lg lg:text-xl leading-1 lg:pt-9 md:pt-4">
                  We help businesses establish a strong and consistent brand
                  identity. Our team collaborates with you to define your
                  brand’s purpose, voice, and positioning, creating a cohesive
                  strategy that resonates with your target audience and fosters
                  long-term loyalty.
                </p>
              </div>
              <div className="md:w-3/6 w-full md:h-full p-0 m-0">
                {/* <div className="background w-3/6 absolute top-0 -left-10 h-full z-10"></div> */}
                <img
                  src="assets/images/brand-strategy-masked.png"
                  alt="Brand Strategy"
                  className="w-full h-[101%] md:block object-cover hidden p-0 scale-100"
                />
                <img
                  src="assets/images/brand-strategy-image.png"
                  alt="Brand Strategy"
                  className="w-full h-full md:hidden block object-cover"
                />
              </div>
            </div>
            {/* End: Brand Strategy */}

            {/* Start: Travel Tech */}
            <div
              className="col-span-full bg-dark/[.4] md:rounded-[25px] rounded-51 md:mt-0 sm:mt-12 mt-5  relative z-20 backdrop-blur-md lg:pr-16 md:pr-10 lg:min-h-[425px] md:min-h-[350px] flex md:flex-row flex-col items-center overflow-hidden lg:mb-12 md:mb-5 md:max-w-none max-w-sm mx-auto"
              data-aos="fade-up"
            >
              <div className="md:w-3/6 w-full md:h-full">
                <img
                  src="assets/images/travel-tech-masked.png"
                  alt="Travel Tech"
                  className="w-full h-[101%] md:block hidden object-cover"
                />
                <img
                  src="assets/images/travel-tech-image.png"
                  alt="Travel Tech"
                  className="w-full h-full md:hidden block object-cover"
                />
              </div>
              <div className="md:w-3/6 md:pr-4 w-full px-9 sm:py-12 pb-12 md:mt-0 sm:-mt-20 -mt-10">
                <h3 className="text-alabaster lg:text-4xl md:text-[28px] leading-none font-semibold inline-block align-middle">
                  <img
                    src="assets/images/travel-tech-icon.svg"
                    alt="Travel Tech"
                    className="inline lg:w-auto md:w-6 object-contain object-left-top"
                  />
                  <span className="ml-3">Travel Tech Solutions</span>
                </h3>
                <p className="text-alabaster md:text-lg lg:text-xl leading-1 lg:pt-9 md:pt-4">
                  Building on our legacy in the ground transportation, we offer
                  specialized tech solutions tailored for the travel and
                  logistics industry. From booking systems to fleet management,
                  we leverage technology to streamline operations, enhance
                  customer experiences, and drive efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
